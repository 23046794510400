.extract-trade-master {
  border: 1px solid #ceba85;
  border-radius: 1rem;
  padding: 1rem 0.5rem;
}

.extract-trade-master header span {
  color: #ceba85;
  font-weight: 600;
  padding: 0 1rem;
  font-size: 1.1rem;
}

.qrcode-container {
  position: relative;
  width: fit-content;
  margin: 0 auto;
}

.qrcode-overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: contain;
  pointer-events: none;
  z-index: 1;
}

.extract-trade-master-statements-months {
  width: 100%;
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  /* gap: 0.5rem; */
  padding: 1rem 0;
  border-bottom: 1px solid #ceba85;
  max-width: 900px;
  margin: auto;
}

.extract-trade-master-statements-months:last-child {
  margin-top: 1rem;
  border-bottom: none;
}

.extract-trade-master-statements-month {
  display: flex;
  align-items: start;
  justify-content: start;
  flex-direction: column;
}

.extract-trade-master-statements-month p {
  margin-bottom: 0.5rem;
}

.reports {
  margin-top: 2rem;
  padding: 1rem;
  border: 1px solid #ceba85;
  border-radius: 1rem;
}

.reports span {
  color: #ceba85;
  font-weight: 600;
  padding: 0 1rem;
  font-size: 1.2rem;
}


.reports-trade-master {
  display: flex;
  flex-direction: column;
  width: 100%;
  max-width: 950px;
  margin: auto;
  padding-top: 0.5rem;
}

.reports-trade-master header {
  display: block;
  align-items: center;
  justify-content: space-between;
  width: 100%;
}

.reports-trade-master header img {
  width: 8rem;
  height: auto;
}

.reports-trade-master header .customer {
  margin-bottom: 1.2rem;
  border-right: 2px solid #ceba85;
  padding-right: 1.6rem;
}

.reports-trade-master header .customer h2 {
  color: #001434;
  text-transform: uppercase;
  font-weight: 600;
  font-size: 1.3rem;
  text-align: start;
}

.reports-trade-master header .customer p {
  color: #001434;
  font-weight: 500;
  font-size: 1.3rem;
  text-align: start;
  margin-top: 0.5rem;
}

.reports-trade-master header .address {
  line-height: 10px;
  margin-bottom: 15rem;
}

.reports-trade-master header .address p {
  margin-bottom: 0rem !important;
  color: #001434;
  font-size: 0.8rem;
}

.reports-trade-master main .balances:first-child {
  margin-top: 0rem;
}

.reports-trade-master main .balances {
  display: flex;
  flex-direction: column;
  margin-top: 2rem;
}

.reports-trade-master main .balances h2 {
  display: flex;
  justify-content: end;
  font-size: 1rem;
  padding-bottom: 5px;
  border-bottom: 2px solid #ceba85;
  font-weight: 700;
}

.reports-trade-master.dashboard main .balances h2 {
  display: flex;
  justify-content: start;
  font-size: 1rem;
  padding-bottom: 5px;
  border-bottom: 2px solid #ceba85;
  font-weight: 700;
  padding: 5px 1rem;
}


.reports-trade-master main .balances .balance {
  display: flex;
  flex-direction: column;
  line-height: 20px;
}

.reports-trade-master.dashboard main .balances .balance {
  padding: 5px 1rem;
}

.reports-trade-master main .balances .balance .balance-data:first-child {
  margin-top: 0rem;
}

.reports-trade-master main .balances .balance .balance-data {
  display: flex;
  width: 100%;
  justify-content: space-between;
  margin-top: 0.5rem;
}

.reports-trade-master main .balances .balance .balance-data span {
  font-weight: 700;
}

.reports-trade-master main .balances .balance .balance-data p {
  margin-left: 0.5rem;
  margin-bottom: 0;
}

.reports-trade-master p.info {
  font-size: 1rem;
  font-weight: 600;
  margin-top: 0px;
  margin-bottom: 0px;
}

.remove-bold {
  font-weight: 600 !important;
}
/* .reports-trade-master footer::before {
  content:url("../assets/images/linha_touro.svg");
  width:100%;
  height:20px;
} */
.reports-trade-master footer {
  margin-top: 5rem;
  border-top: 1px solid #ceba85;
  display: flex;
  flex-direction: column;
  line-height: 20px;
}

.reports-trade-master footer .title {
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-size: 1rem;
  font-weight: 700;
}

.reports-trade-master footer p {
  font-size: 0.8rem;
  font-weight: 600;
}

.reports-trade-master footer a {
  font-size: 0.8rem;
  cursor: pointer;
  color: #001434 !important;
}

.trade-master-smaller-text {
  font-size: 0.9rem;
}

.timer-div {
  display: flex;
  grid-auto-flow: row;
  justify-content: center;
  align-items: center;
  align-content: center;
}

.timer-div-parent {
  min-height: 4rem;
  align-items: center;
}

.timer-opt {
  background-color: rgb(239, 239, 239);
  height: 4rem;
  width: 4.3rem;
  border-radius: 20px;
  padding: 2px;
  margin: 2px;
  text-align: center;
  align-items: center;
  align-content: center;
}

.timer-num {
  font-size: 1.5rem;
  font-weight: 600;
  color: #001434;
}

.timer-words {
  font-size: 0.75rem;
  font-weight: 300;
  color: #001434;
}

#exp-timer-txt {
  font-size: 0.9rem;
  font-weight: 600;
  color: red;
}

#warning-black-regular {
  font-size: 0.9rem;
  font-weight: 400;
  color: black;
}

#warning-black-bold {
  font-size: 0.9rem;
  font-weight: 600;
  color: black;
}

.modal-account {
  background-color:rgb(239, 239, 239);
  padding: 10px;
  margin: 10px;
}

.timer-expired-div {
  display: flex;
  grid-auto-flow: row;
  justify-content: center;
  align-items: center;
  align-content: center;
}

.dashboard-header {
  display: flex;
  justify-content: space-between;
  align-content: space-between;
}

.refferences {
  color: "#212529";
  display: flex;
  justify-content: space-between;
  font-weight: 400;
  font-size: 16px;
}

#aport-refferences {
  margin-right: 1rem;
}

.dashboard-bold {
  display: flex;
  justify-content: space-between;
}

.margins {
  margin-top: 2rem;
}

.welcome-btn {
  min-width: 150px;
  min-height: 50px;
}

@media print
{
  /* table { page-break-after:auto }
  tr    { page-break-inside:avoid; page-break-after:auto }
  td    { page-break-inside:avoid; page-break-after:auto }
  thead { display:table-header-group }
  tfoot { display:table-footer-group } */

  .balances   { page-break-inside:avoid; } /* This is the key */
}

.div-export-csv {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;
  width: 100%;
}

.export-csv {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  margin-top: 1rem;
  margin-bottom: 1rem;
}

#currecies-card-login {
  margin-left: 254px;
}

.inv-sum-container {
  border: 1.5px solid #ceba85;
  border-radius: 15px;
  padding: 20px;
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  align-items: flex-end;
  margin-bottom: 20px;
}

.inv-sum-div {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-around;
  width: 100%;
}

.inv-sum-item {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-items: center;
  margin-bottom: 20px;
  padding: 10px;
  border-radius: 5px;
  max-width: 300px;
}

.inv-sum-title {
  width: 100%;
  text-align: center;
  margin-bottom: 20px;
  color:#ceba85;
  font-weight: bolder;
  letter-spacing: 1px;
}

.inv-sum-item-title {
  font-weight: 600;
  margin-bottom: 8px;
  text-decoration-line: underline;
  text-underline-offset: 6px;
  text-decoration-color: #ceba85;
}

.inv-sum-item-text {
  text-transform: capitalize;
}

.inv-warningtext {
  font-weight: 400;
  font-size: 0.70rem;
}


.inv-sum-item-refBRL {
  font-style: italic;
  font-weight: 200;
  font-size: 0.95rem;
}

.inv-sum-item-link {
  background-color: #ceba85;
  padding: 5px 15px 5px 15px;
  border-radius: 5px;
  color: #FFFFFF !important;
}

.inv-sum-item-link:hover {
  background-color: #bfab74;
  color: #FFFFFF;
  text-decoration-line: none !important;
}

.inv-id-div {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-between;
  width: 100%;
  padding-left: 20px;
  padding-right: 20px;
}

.inv-id-buttons {
  display: flex;
  width: 100%;
  justify-content: flex-end;
  margin: 15px 15px 5px 0;
}

.inv-id-item {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-items: center;
  margin-bottom: 20px;
  padding: 10px;
  border-radius: 5px;
  max-width: 300px;
  min-width: 250px;
}

.inv-id-item-title {
  font-weight: 700;
  margin-bottom: 3px;
  color:#ceba85;
}

.inv-id-months {
  width: 100%;
  margin-top: 25px;
  margin-bottom: 8px;
  font-weight: 700;
  font-size: 1.1rem;
  padding-left: 20px;
  color:#ceba85;
}

#inv-id-month-range {
  font-style: italic;
  font-weight: 500;
  font-size: 1rem;
  color:#ceba85;
}

.inv-id-line {
  height: 2px;
  background-color: #ceba85;
  width: 100%;
  margin-bottom: 10px;
}

.inv-id-statement {
  display: flex;
  justify-content: space-between;
  width: 100%;
}

.inv-id-refBRL {
  font-style: italic;
  font-weight: 200;
  font-size: 0.95rem;
}

.inv-id-statement-text {
  font-weight: 600;
}

.internal-statement {
  padding-left: 20px;
}

.inv-sum-no-investment {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-items: center;
  margin-bottom: 20px;
  padding: 10px;
  border-radius: 5px;
}

.no-investment-cards {
  display: block;
}

.bigger-ninv {
  margin-top: 20px;
  font-size: 1.6rem;
  font-weight: 600;
  margin-bottom: 20px;
  text-decoration-line: underline;
  text-underline-offset: 6px;
  text-decoration-color: #ceba85;
}

.select-input {
  padding: 8px;
  border: 1px solid#ceba85;
  border-radius: 4px;
  font-size: 14px;
  background-color: #fff;
  text-align: center;
  color: #212529;
  width: 80%;
  margin-left: 10%;
  margin-right: 10%;
}

.new-inv-div {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-items: center;
}


.new-inv-user {
  font-weight: bold;
  font-size: 1.2rem;
  text-align: center;
  margin-top: 20px;
}

.new-inv-select-input {
  padding: 8px;
  border: 1px solid#ceba85;
  border-radius: 10px;
  font-size: 14px;
  background-color: #fff;
  text-align: center;
  color: #212529;
  width: 100%;
}

.new-inv-forms {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-items: center;
  margin-bottom: 20px;
  padding: 10px;
  border-radius: 5px;
  max-width: 500px;
}

.new-inv-btn-div {
  margin-top: 20px;
  width: 300px;
  display: flex;
  align-items: center;
  justify-content: space-evenly;
}

.new-inv-btns {
  min-width: 100px;
}

.inv-hist-images {
  width: 800px;
  margin-top:  -150px;
  margin-bottom: -150px;
  margin-right: 50px;
}

.inv-hist-graphs {
  width: 400px;
  margin-top:  -200px;
  margin-right: 250px;
  margin-bottom: 50px;
}

@media (min-width: 900px)
{
  .no-investment-cards {
    display: flex !important;
    flex-direction: row !important;
  }
}

@media (max-width: 900px)
{
  #currecies-card-login {
    margin-left: 0px;
  }
  .no-investment-cards {
    display: block !important;
    align-items: center;
  }

  .inv-sum-container {
    display: block !important;
    border: 1.5px solid #ceba85;
    border-radius: 15px;
    padding: 20px;
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    align-items: flex-end;
    margin-bottom: 20px;
  }

  .inv-sum-no-investment {
    align-items: center;
    justify-items: center;
    margin-bottom: 20px;
    padding: 50px;
    border-radius: 5px;
  }

  .inv-sum-div {
    display: block !important;
    width: 175% !important;
  }
}