.legal-back-btn {
  display: inline-flex;
  justify-content: start;
  width:100%;
  margin: 10px 0px 20px 20px;
}

.legal-div {
  margin-left: 20px;
  margin-right: 20px;
}

.legal-title {
  margin-top: 20px;
  font-weight: bold;
  text-align: center;
  text-decoration-line: underline;
  text-underline-offset: 6px;
  text-decoration-color: #ceba85;
}

.legal-subtitle {
  margin-top: 50px;
  text-align: left;
}

.legal-text {
  margin-top: 20px;
  text-align: justify;
  font-size: 15px;
}

.input-legal-div {
  display: flex;
  flex-direction: row;
}

.input-legal {
  margin: 10px 20px 10px 0px;
}

.kyc-policy {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 20px;
  background-color: #f1f1f1;
  min-height: 280px;
  border-radius: 10px;
}

.kyc-policy-title {
  margin-bottom: 1.6rem;
  font-weight: bold;
}