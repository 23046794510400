:root {
    --bg-color: #FAFAFA;
    --blue-color: #011838;
    --gold-color: #CEBA86;
    --white-color: #FFFFFF;
}


.lp-header {
    z-index: 2;
    position: fixed;
}

.widget-div {
    width: calc(350px * 10);
    display: flex;
    flex-direction: row;
    align-content: center;
    align-items: center;
    font-size: 13px;
    background-color: var(--white-color);
}

.widget-currency {
    display: flex;
    flex-direction: row;
    margin: 10px;
    align-content: center;
    align-items: center;
    min-width: 350px;
}

#first-bitcoin {
    margin-left: 200px;
}

.widget-currency-img {
    height: 20px;
    width: 20px;
    margin-right: 10px;
}

.widget-name-div {
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    margin-right: 10px;
}

.widget-name-symbol {
    inline-size: min-content;
}

.widget-name-currency {
    font-weight: bold;
}

.widget-price-div {
    display: flex;
    flex-direction: row;
}

.widget-brl-price {
    font-weight: bold;
    margin-right: 10px;
}

.widget-price-variation {
    margin-right: 10px;
}

.widget-currency {
    animation: translateinfinite 25s linear infinite;
}

.lp-header-false {
    z-index: 1;
    position: fixed;
    display: flex;
    flex-direction: row;
    width: 100%;
    transition: 2s, ease-in-out;
}

.lp-header-true {
    z-index: 1;
    position: fixed;
    background-color: var(--blue-color);
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    align-content: center;
}

.lp-header-logo-false {
    max-width: 150px;
    max-height: 150px;
    margin: 1rem;
}

.lp-header-logo-true {
    max-width: 100px;
    max-height: 100px;
    margin: 1rem;
    margin-left: 3rem;
}

.lp-header-buttons {
    min-width: 300px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-around;
    justify-items: center;
    margin: 1rem;
}

#login-bnt {
    border: 2px solid var(--gold-color);
    background-color: var(--blue-color);
    border-radius: 5px;
    font-weight: bold;
    font-size: 12px;
    color: var(--gold-color);
    padding: 0.5rem 1.5rem;
    margin: 0px 16px 4px 0px;
}

#register-bnt {
    border: 2px solid var(--gold-color);
    background-color: var(--gold-color);
    border-radius: 5px;
    font-weight: bold;
    font-size: 12px;
    color: var(--blue-color);
    padding: 0.5rem 1.5rem;
    margin: 0px 16px 4px 0px;
}

#login-bnt-start {
    border: none;
    background-color: var(--blue-color);
    border-radius: 5px;
    font-weight: bold;
    font-size: 12px;
    color: var(--gold-color);
    padding: 0.5rem 1.5rem;
    margin: 16px;
    width: 118.35px;
}

#login-bnt-start:hover {
    background-color: #06295a;
}

#bnt-start {
    border: none;
    background-color: var(--blue-color);
    border-radius: 5px;
    font-weight: bold;
    font-size: 12px;
    color: var(--gold-color);
    padding: 0.5rem 1.5rem;
    margin: 16px;
    width: 118.35px;
}

#bnt-start:hover {
    background-color: #06295a;
}

.lp-start {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    min-height: 950px;
    width: 100%;
    clip-path: polygon(0 0, 100% 0, 100% calc(100% - 70px), 0 100%);
}

.lp-start-divs {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin: 2rem;
}

.start-left-div {
    max-width: 40rem;
}

.title {
    font-size: 4rem;
    font-weight: bolder;
    color: var(--blue-color);
    margin-bottom: 1rem;
}

.subtitle {
    font-size: 1.35rem;
    font-weight: 300;
    color: var(--blue-color);
    margin-bottom: 0.5rem;
}

.bull-head {
    height: 600px;
    width: 600px;
}

.section-2 {
    width: 100%;
    background-color: var(--blue-color);
    min-height: 1600px;
    clip-path: polygon(0 70px, 100% 0, 100% 100%, 0 100%);
    margin-top: -180px;
    margin-left: 0;
    background: url('../assets/images/bitcoin-trade.jpeg');
    background-size: cover;
    box-shadow: inset 0 0 0 2000px rgba(0, 21, 53, 0.8);
}

.landing-page {
    width: 100%;
    overflow-x: hidden;
}

.btn-login {
    margin: 1rem;
}

.btn-register {
    margin: 1rem;
}

.s2-title {
    padding-top: 10rem;
    font-size: 3rem;
    font-weight: bolder;
    color: var(--gold-color);
    margin-bottom: 1rem;
}



/* timeline component */


.timeline {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin-top: 9rem;
    background-color: rgba(255, 255, 255, 0.1);
    ;
    border-radius: 20px;
    position: relative;
    width: 860px;
    margin: 0px auto;
    padding: 20px;
    padding-bottom: 150px;
    list-style-type: none;
}

.timeline:before {
    position: absolute;
    left: 50%;
    top: 0;
    content: ' ';
    display: block;
    width: 6px;
    height: 100%;
    margin-left: -3px;
    background: var(--white-color);
    z-index: 5;
}

.timeline-li {
    padding: 1em 0;
}

.timeline-li::after {
    content: "";
    display: block;
    height: 0;
    clear: both;
    visibility: hidden;
}


.direction-l {
    display: flex;
    flex-direction: row-reverse;
    align-items: center;
    margin-right: 332px;
    position: relative;
}

.box-l {
    display: flex;
    flex-direction: column;
    float: left;
    text-align: right;
    width: 300px;
    margin: 1rem;
}

.direction-r {
    display: flex;
    flex-direction: row;
    align-items: center;
    margin-left: 332px;
    position: relative;
}

.box-r {
    display: flex;
    flex-direction: column;
    float: right;
    text-align: left;
    width: 300px;
    margin: 1rem;
}

.s2-timeline-div {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    justify-content: center;
    margin: 1rem;
    min-width: 500px;
    max-width: 600px;
}

.years-true {
    font-size: 3.5rem;
    font-weight: bolder;
    color: var(--white-color);
}

.ball-true {
    background-color: var(--white-color);
    border-radius: 100%;
    height: 25px;
    width: 25px;
}

.text-true {
    color: var(--white-color);
    font-size: 0.95rem;
}

.years-false {
    font-size: 2rem;
    font-weight: bolder;
    color: var(--white-color);
    opacity: 0.5;
}

.ball-false {
    background-color: var(--white-color);
    border-radius: 100%;
    min-height: 15px;
    min-width: 15px;
    margin: 30px;
    opacity: 0.5;
}

.text-false {
    color: var(--white-color);
    font-size: 0.95rem;
    opacity: 0.5;
}

.text-div {
    margin-top: 10px;
    padding: 10px;
    max-width: 400px;
}


/* End of timeline  component */

.section-3 {
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 3rem;
    position: relative;
    margin-top: -100px;
}

.section-3-div {
    background-color: var(--white-color);
    width: 800px;
    display: flex;
    justify-content: center;
    flex-direction: column;
    border-radius: 20px;
    box-shadow: 5px 5px 5px rgba(0, 0, 0, 0.4);
}

.s3-btn-div {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 1rem;
}

.s3-title {
    font-size: 1.7rem;
    font-weight: bolder;
    color: var(--blue-color);
    padding: 30px;
    margin: 1rem;
    margin-left: 3rem;
    margin-right: 2rem;
    margin-bottom: 0rem;
}

.section-4 {
    display: flex;
    justify-content: center;
    align-items: center;
}

.section-4-div {
    min-height: 900px;
}

.s4-title {
    font-size: 3rem;
    font-weight: bolder;
    color: var(--blue-color);
    padding: 30px;
    margin: 1rem;
    margin-left: 3rem;
    margin-right: 2rem;
    margin-bottom: 2rem;
}

.div-itens {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin: 1rem;
    margin-top: 0rem;
    max-width: 800px;
}

.item {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    background-color: var(--white-color);
    box-shadow: 5px 5px 5px rgba(0, 0, 0, 0.4);
    border-radius: 20px;
    margin: 1rem;
    margin-top: 0rem;
    padding: 20px;
    min-height: 250px;
    min-width: 800px;
    transition: .5s ease-in-out;
}

.item:hover {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    background-color: var(--white-color);
    box-shadow: 5px 5px 5px rgba(0, 0, 0, 0.4);
    border-radius: 20px;
    margin: 1rem;
    margin-top: 0rem;
    padding: 20px;
    min-height: 300px;
    min-width: 850px;
}

.icon {
    height: 30px;
    width: 30px;
    margin-bottom: 1rem;
    color: var(--gold-color);
}

.item-title {
    font-size: 1.2rem;
    font-weight: 600;
    color: var(--blue-color);
    margin-bottom: 1rem;
    text-align: center;
    line-height: 20px;
}

.item-text {
    font-size: 0.7rem;
    font-weight: 300;
    color: var(--blue-color);
    margin-bottom: 1rem;
    text-align: center;
    max-width: 700px;
}

.section-5-divider {
    position: relative;
    background: var(--gold-color);
    height: 30px;
    border-top-left-radius: 50% 100%;
    border-top-right-radius: 50% 100%;
    margin-top: 2rem;
}

.section-5 {
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
    background-color: var(--gold-color);
    height: 550px;
}

.s5-div {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    margin: 1rem;
    margin-top: 0rem;
    max-width: 900px;
}

.white-frame {
    display: flex;
    align-content: flex-end;
    justify-content: space-around;
    background-color: var(--white-color);
    border-top-right-radius: 300px;
    border-top-left-radius: 300px;
    max-width: 350px;
    margin-top: 60px;
    height: 500px;
    margin-bottom: 0;
}

.s5-right-div-parent {
    display: flex;
    align-content: flex-end;
    height: 484px;
    margin-left: 4rem;
}

.s5-title {
    font-size: 2.5rem;
    font-weight: bolder;
    color: var(--blue-color);
    padding: 30px;
}

.s5-text {
    font-size: 1rem;
    font-weight: 300;
    color: var(--blue-color);
}

.section-5b {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    position: relative;
    background-color: var(--gold-color);
    height: 700px;
}

.s5b-div {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-content: center;
    margin-top: -150px;
}

.s5b-item {
    width: 900px;
    margin: 10px;
    background-color: var(--white-color);
    padding: 20px;
    border-radius: 15px;
}

.s5b-title {
    font-size: 1.3rem;
    font-weight: 500;
    color: var(--gold-color);
}

.s5b-text {
    margin-top: 15px;
    font-size: 1rem;
    font-weight: 300;
    color: var(--blue-color);
}

.section-6 {
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
    background-color: var(--bg-color);
    height: 600px;
}

.s6-div {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin: 1rem;
    margin-top: 0rem;
    max-width: 900px;
}

.s6-title {
    font-size: 2.5rem;
    font-weight: bolder;
    color: var(--blue-color);
    padding: 30px;
}

.s6-text {
    font-size: 0.8rem;
    font-weight: 300;
    color: var(--blue-color);
}

.s6-name {
    font-size: 1rem;
    font-weight: 700;
    color: var(--gold-color);
}

.s6-testimonials-div {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    margin: 1rem;
    margin-top: 0rem;
    width: 900px;
    max-width: 1000px;
}

.testimonial {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    border-radius: 20px;
    box-shadow: 5px 5px 5px rgba(0, 0, 0, 0.4);
    background-color: var(--white-color);
    margin: 2rem;
    padding: 20px;
    height: 330px;
    transition: .5s ease-in-out;
}

.testimonial:hover {
    height: 350px;
    box-shadow: 15px 15px 15px rgba(0, 0, 0, 0.2);
}

#testimonial1 {
    background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 1440 320'%3E%3Cpath fill='%23011838' fill-opacity='1' d='M0 64L60 90.7C120 117 240 171 360 176C480 181 600 139 720 149.3C840 160 960 224 1080 213.3C1200 203 1320 117 1380 74.7L1440 32L1440 320L1380 320C1320 320 1200 320 1080 320C960 320 840 320 720 320C600 320 480 320 360 320C240 320 120 320 60 320L0 320Z'%3E%3C/path%3E%3C/svg%3E");
    background-repeat: no-repeat;
    background-position: bottom left;
    background-size: 450px 100px;
}

#testimonial2 {
    background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 1440 320'%3E%3Cpath fill='%23011838' fill-opacity='1' d='M0 64L60 90.7C120 117 240 171 360 176C480 181 600 139 720 149.3C840 160 960 224 1080 213.3C1200 203 1320 117 1380 74.7L1440 32L1440 320L1380 320C1320 320 1200 320 1080 320C960 320 840 320 720 320C600 320 480 320 360 320C240 320 120 320 60 320L0 320Z'%3E%3C/path%3E%3C/svg%3E");
    background-repeat: no-repeat;
    background-position: bottom center;
    background-size: 450px 100px;
}

#testimonial3 {
    background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 1440 320'%3E%3Cpath fill='%23011838' fill-opacity='1' d='M0 64L60 90.7C120 117 240 171 360 176C480 181 600 139 720 149.3C840 160 960 224 1080 213.3C1200 203 1320 117 1380 74.7L1440 32L1440 320L1380 320C1320 320 1200 320 1080 320C960 320 840 320 720 320C600 320 480 320 360 320C240 320 120 320 60 320L0 320Z'%3E%3C/path%3E%3C/svg%3E");
    background-repeat: no-repeat;
    background-position: bottom right;
    background-size: 450px 100px;
}

.picture {
    height: 50px;
    width: 50px;
    border-radius: 100%;
    margin-bottom: 1rem;
}

.quote {
    height: 10px;
    width: 10px;
    margin-bottom: 2rem;
    margin-top: -1rem;
    color: var(--gold-color);
}

.section-7 {
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
    background-color: var(--blue-color);
    height: 800px;
}

.s7-title {
    font-size: 2.5rem;
    font-weight: bolder;
    color: var(--white-color);
    padding: 30px;
}

.s7-big-div {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin: 1rem;
    margin-top: 0rem;
    max-width: 800px;
}

.s7-div {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    margin: 1rem;
    margin-top: 0rem;
}

.s7-clicked {
    background-color: var(--gold-color);
    color: var(--white-color);
    padding: 20px;
    border-radius: 20px;
    margin: 1rem;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
}

.clicked-icon {
    height: 20px;
    width: 20px;
}

.s7-hold {
    background-color: rgba(255, 255, 255, 0.05);
    color: var(--bg-color);
    padding: 20px;
    border-radius: 20px;
    margin: 1rem;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
}

.hold-icon {
    height: 20px;
    width: 20px;
}

.s7-left-div {
    width: 400px;
    margin: 2rem;
}

.s7-right-div {
    width: 600px;
    margin: 2rem;
    background-color: rgba(255, 255, 255, 0.08);
    border-radius: 20px;
    min-height: 460px;
    padding: 30px;
    color: var(--white-color);
}

.address-container {
    display: flex;
    justify-content: center;
    width: 100%;
    margin-top: 10px;
  }

.address-text {
    text-align: center;
    color: #ffffff;
    /* Adjust the text color if needed */
}

.bt-bar-content {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    max-width: 1200px;
    margin-bottom: 10px;
}

.lp-bottom-bar {
    display: flex;
    flex-direction: column;
    align-items: center;
    background-color: var(--blue-color);
    color: var(--white-color);
    padding: 20px;
}

.bt-bar-icon {
    height: 50px;
    width: 50px;
}

.social-media {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 15px;
}

.bt-bar-text {
    font-size: 0.8rem;
    font-weight: lighter;
    font-style: italic;
}

.section-8 {
    display: flex;
    justify-content: center;
    align-items: center;
    align-content: center;
    position: relative;
    background-color: var(--blue-color);
    height: 400px;
    color: var(--white-color);
}

#support-bt {
    border: none;
    background-color: var(--gold-color);
    border-radius: 5px;
    font-weight: bold;
    font-size: 12px;
    color: var(--blue-color);
    padding: 0.5rem 1.5rem;
    margin: 16px;
    width: 120px;
    height: 35px;
}

.s8-div {
    max-width: 800px;
    display: flex;
    flex-direction: column;
    align-content: center;
    align-items: center;
}

.s8-text {
    font-size: 20px;
    font-weight: 300;
}

.bt-bar-links {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 15px;
}


@keyframes translateinfinite {
    100% {
        transform: translateX(calc(-180px * 12));
    }
}

@media screen and (max-width: 900px) {
    .s5b-item {
        max-width: 800px;
    }

    .s7-div {
        max-width: 800px;
    }

    .s7-left-div {
        margin: 0px;
    }

    .s7-right-div {
        margin: 0px;
        min-height: 585px;
        max-width: 500px;
        min-width: 500px;
    }

    .s8-div {
        max-width: 800px;
    }
}

@media screen and (max-width: 600px) {
    .lp-header-true {
        z-index: 1;
        position: fixed;
        background-color: var(--blue-color);
        width: 100%;
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
        align-content: center;
    }

    .lp-header-logo-false {
        max-width: 100px;
        max-height: 100px;
    }

    .lp-header-logo-true {
        max-width: 50px;
        max-height: 50px;
        margin: 1rem;
    }

    .lp-header-buttons {
        min-width: 200px;
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: space-around;
        justify-items: center;
        margin: 1rem;
    }

    .login-button-div {
        width: 400px;
        display: row;
        flex-wrap: no-wrap;
        margin-bottom: 130px;
    }

    #bnt-start {
        margin: 0;
    }

    #bnt-register {
        margin: 0;
    }

    .lp-start {
        flex-direction: column;
    }

    .start-left-div {
        width: 80%;
        margin: 2rem;
        margin-top: 170px;
    }

    .bull-head {
        max-width: 350px;
        margin-top: -50px;
        margin-bottom: 60px;
    }

    .s2-title {
        max-width: 300px;
        margin-left: 50px;
    }

    .section-4-div {
        flex-direction: column;
        align-items: center;
        justify-content: center;
        justify-items: center;
        max-width: 350px;
    }

    .timeline {
        max-width: 350px;
        min-height: 1600px;
        margin-bottom: 50px;
    }

    .ball-false {
        margin: 0px;
    }

    .ball-true {
        margin: 0px;
    }

    .text-true {
        max-width: 130px;
    }

    .text-false {
        max-width: 130px;
    }

    .years-true {
        font-size: 2.7rem;
    }

    .box-l {
        width: 130px;
        margin-left: 185px;
    }

    .box-r {
        max-width: 300px;
    }

    .div-itens {
        display: flex;
        flex-direction: column;
        justify-items: center;
        margin-top: 0;
        margin: 10px;
    }

    .item {
        max-width: 350px;
        min-width: 350px;
        min-height: 400px;
    }

    .item:hover {
        max-width: 370px;
        min-width: 370px;
        min-height: 410px;
    }

    .item-text {
        margin: 10px;
        max-width: 400px;
    }

    .section-5 {
        min-height: 1000px;
    }

    .s5-div {
        flex-direction: column;
        max-width: 350px;
    }

    .s5-image {
        margin-right: 140px;
    }

    .section-5b {
        min-height: 1000px;
    }

    .s5-right-div {
        max-width: 350px;
    }

    .s5b-div {
        margin-top: -60px;
        max-width: 350px;
    }

    .s5b-item {
        max-width: 350px;
    }

    .section-6 {
        height: 1500px;
    }

    .s6-div {
        flex-direction: column;
        max-width: 420px;
    }

    .s6-testimonials-div {
        flex-direction: column;
        max-width: 420px;
    }

    .section-7 {
        height: 1200px;
    }

    .s7-div {
        max-width: 350px;
    }

    .s7-left-div {
        margin: 0px;
    }

    .s7-right-div {
        margin: 0px;
        min-height: 710px;
        max-width: 200px;
        min-width: 200px;
    }

    .s8-div {
        max-width: 350px;
    }

    .bt-bar-links {
        display: flex;
        flex-direction: column;
        margin-left: 10px;
        margin-right: 10px;
    }
}

@media screen and (max-width: 360px) {
    .lp-header-buttons {
        min-width: 200px;
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: space-around;
        justify-items: center;
        margin: 1rem;
    }

    #register-bnt {
        min-width: 100px;
        padding-left: 2px;
        padding-right: 2px;
    }

    .login-btn-div {
        max-width: 200px;
        justify-content: center;
        align-items: center;
        margin-left: 52px;
        margin-top: 20px;
    }

    .btn-register {
        margin-left: 0;
    }

    .title {
        font-size: 2.7rem;
        line-height: 40px;
        margin-bottom: 40px;
    }

    .subtitle {
        font-size: 1rem;
    }

    .bull-head {
        max-width: 300px;
        margin-top: -120px;
        margin-bottom: 60px;
    }

    .s2-title {
        font-size: 2.7rem !important;
        line-height: 40px;
        margin-bottom: 40px;
        margin-left: 14px;
    }

    .section-2-divs {
        max-width: 360px;
    }

    .timeline {
        max-width: 300px;
        min-height: 1600px;
        margin-bottom: 50px;
    }

    .ball-false {
        margin: 0px;
    }

    .ball-true {
        margin: 0px;
    }

    .text-true {
        max-width: 110px;
    }

    .text-false {
        max-width: 110px;
    }

    .years-true {
        font-size: 2.7rem;
    }

    .box-l {
        width: 110px;
        margin-left: 207px;
    }

    .box-r {
        max-width: 300px;
    }

    .s4-title {
        font-size: 2.5rem !important;
        line-height: 40px;
        margin-bottom: 40px;
        margin-left: 14px;
    }

    .section-4-div {
        max-width: 300px;
    }

    .div-itens {
        display: flex;
        flex-direction: column;
        justify-items: center;
        margin-top: 0;
        margin: 10px;
    }

    .item {
        max-width: 300px;
        min-width: 300px;
        min-height: 400px;
    }

    .item:hover {
        max-width: 310px;
        min-width: 310px;
        min-height: 410px;
    }

    .item-text {
        margin: 10px;
        max-width: 270px;
    }

    .section-5 {
        min-height: 1200px;
    }

    .section-5b {
        min-height: 1200px;
    }

    .s5-title {
        font-size: 2.3rem !important;
        line-height: 40px;
    }

    .s5b-title {
        font-size: 1.6rem !important;
        line-height: 40px;
    }

    .s5-div {
        max-width: 300px;
    }

    .s5b-div {
        max-width: 300px;
        padding-left: 0;
    }

    .s5b-item {
        max-width: 300px;
        margin-left: 0;
    }

    .s7-title {
        font-size: 2rem !important;
        line-height: 40px;
    }

    .s7-big-div {
        max-width: 280px;
        margin-left: 0px;
    }

    .section-7 {
        height: 1200px;
    }

    .s7-div {
        max-width: 280px;
        margin-left: 15px;
    }

    .s7-left-div {
        margin: 0px;
        margin-left: 0px;
        max-width: 160px;
    }

    .s7-right-div {
        margin-left: -10px;
        min-height: 895px;
        max-width: 170px;
        min-width: 170px;
        padding-right: 0px;
        padding: 15px;
    }

    .s7-text {
        max-width: 170px;
        padding: 0px;
    }

    .s8-div {
        max-width: 270px;
    }

    .lp-bottom-bar {
        padding: 0px;
        padding-top: 20px;
        padding-bottom: 20px;
    }

    .bt-bar-icon {
        height: 15px;
        width: 15px;
    }

    .fa-2x {
        font-size: 1rem !important;
    }

}