.title-buyOrSell {
  display: flex;
  justify-content: space-evenly;
  align-items: center;
}

.title-buyOrSell h5 {
  cursor: pointer;
}

.buy-active {
  color: green;
  border-bottom: 2px solid green;
}

.sell-active {
  color: red;
  border-bottom: 2px solid red;
}

.order-container {
  display: flex;
  width: 100%;
  align-items: flex-start;
}

.order-container table {
  border-collapse: collapse;
  width: 100%;
  text-align: center;
  border: 1px solid #bfa972;
}

.orderbook-title {
  background-color: rgba(191, 169, 114, 0.7);
}

.orderbook-fees {
  margin: 20px;
  max-width: 90% !important;
}

.ob-fees-title {
  margin-bottom: 1rem;
}

.bold-title {
  font-weight: bold !important;
}

.not-bold-input {
  font-weight: normal !important;
}

h1,
h2 {
  text-align: center;
}
td,
th {
  padding: 6px;
}
tr:nth-child(even) {
  background-color: #f2f2f2;
}

tr:hover {
  background-color: #bfa972;
}
