.notif-row-true {
  border: #CEBA84 3px solid !important;
}

.renewing-row {
  color: #d63d3d;
}

.table_kyc {
  display: grid;
  grid-template-columns: 2fr 2fr 2fr 2fr 2fr 2fr;
  gap: 10px;
}

.table_kyc_col {
  display: flex;
  align-items: center;
  justify-content: start;
  flex-direction: column;
  padding: 0.5rem 0.3rem;
}

.table_kyc_col:nth-child(1) {
  align-items: start;
}

.table_kyc_col:nth-child(5) {
  justify-content: center;
  flex-direction: column;
}

.table_kyc_action {
  margin: 5px auto;
  display: flex;
  align-items: center;
}

.table_kyc_action span {
  font-weight: 600;
}

.table_kyc_col span {
  font-weight: 600;
  white-space: nowrap;
}

.table_kyc_col input,
.table_kyc_col input[type="button"] {
  border-radius: 10px;
  outline: none;
  padding: 5px 10px;
  margin: 5px 0;
  border: 1px solid gray;
}

.table_kyc_col input[type="button"] {
  text-align: center;
}

.panel_wallets {
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  margin: auto;
  width: 100%;
}

.fees-container {
  display: grid;
  width: 100%;
  grid-template-columns: auto auto auto;
   border-right-width: 2px;
  border-left-width: 2px;
  /* border-right: 2px solid #bfa972; */
  margin-top: 1rem;
}

.fees-card {
  width: 100%;
  display: flex;
  border-right: 1px solid #bfa972;
  flex-direction: column;
  padding: 0 25px;
  margin-Top: 40px;
}

.fees-title {
  margin-bottom: 1rem;
  /* border-bottom: 2px solid #bfa972; */
  text-align: center;
}

.text-center {
  width: 100%;
  text-align: center;
  margin: 50px 0 20px 0;
}

.select-manage-fees {
  background-color: #E9ECEF;
  border: 1.5px solid #E9ECEF;;
}
