html,
body,
#root,
#tsparticles {
  height: 100%;
  margin: 0;
  padding: 0;
}

body {
  background-color: #fafafa !important;
  /* overflow-x: hidden; */
}

html {
  width: 100%;
  overflow-x: hidden; 
}

.renewing-row {
  color: #ff1111;
}

.kycLevel1Row {
  background-color: #b1acac;
}

.d-table {
  height: 100%;
  display: table;
}

.container-fluid {
  padding-right: 0;
}

.d-table-row {
  height: 100%;
  display: table-row;
}

a {
  color: #ceba85 !important;
}

.clickable {
  color: #ceba85 !important;
}

.clickable:hover {
  text-decoration: underline !important;
  cursor: pointer !important;
}

.link-file {
  color: #0099ff !important;
  font-size: 15px !important;
}

.card {
  background-color: transparent !important;
  border-radius: 8px !important;
  border-color: #b3b3b3 !important;
}

.default-bg-color {
  background-color: #fafafa !important;
}

.font-weight-light {
  font-size: 19px;
  color: #263346 !important;
}

.bold {
  font-weight: bold;
}

.semi-bold {
  font-weight: 500;
}

.input {
  border-radius: 10px !important;
  border-width: 1px !important;
  border-color: #ceba85 !important;
}

.input.append {
  border-radius: 10px !important;
  border-bottom-right-radius: 0px !important;
  border-top-right-radius: 0px !important;
  border-right: 0px;
  border-width: 1px !important;
  border-color: #ceba85 !important;
}

.append-from-input {
  border-left: 0px;
  border-bottom-left-radius: 0px !important;
  border-top-left-radius: 0px !important;
  background-color: white;
}

.invalid-feedback.show {
  display: block !important;
}

.sign-card {
  background-color: #fafafa9d !important;
  border-radius: 20px !important;
  padding: 20px !important;
  box-shadow: 0px 0px 8px 0px rgba(0, 0, 0, 0.3);
  -webkit-box-shadow: 0px 0px 8px 0px rgba(0, 0, 0, 0.3);
  -moz-box-shadow: 0px 0px 8px 0px rgba(0, 0, 0, 0.3);
}

.price-variation {
  background-color: #f0f0f0;
  padding: 2px;
  font-size: 14px;
  font-weight: bold;
  display: flex;
  justify-content: center;
  align-content: center;
  height: 25px;
  width: 65px;
  border-radius: 20px;
}

.currency-card-row {
  height: 150px !important;
  overflow: hidden !important;
  width: 100%;
  display: inline-flex;
  flex-direction: row;
  justify-content: center;
}

.currency-card-component {
  margin:  20px;
  margin-top: 10px;
}

.currency-card {
  background-color: #ffffff !important;
  border-radius: 10px !important;
  padding: 15px 15px 10px 15px !important;
  width: 140px;
  height: 118px;
  box-shadow: 0px 0px 8px 0px rgba(0, 0, 0, 0.1);
  -webkit-box-shadow: 0px 0px 8px 0px rgba(0, 0, 0, 0.3);
  -moz-box-shadow: 0px 0px 8px 0px rgba(0, 0, 0, 0.3);
}

.currency-text-name {
  font-weight: bold;
  font-size: 13px !important;
  color: #001535 !important;
}

.currency-text-symbol {
  font-size: 8px !important;
  color: #001535 !important;
}

.currency-text-info {
  font-size: 12px !important;
  color: #263346 !important;
  margin-top: 5px;
}

.currency-card-small {
  width: 95px;
  height: 85px;
  background-color: #fafafa !important;
  border-radius: 8px !important;
  padding: 8px !important;
  box-shadow: 0px 0px 8px 0px rgba(0, 0, 0, 0.3);
  -webkit-box-shadow: 0px 0px 8px 0px rgba(0, 0, 0, 0.3);
  -moz-box-shadow: 0px 0px 8px 0px rgba(0, 0, 0, 0.3);
}

.currency-text-name-small {
  font-weight: bold;
  font-size: 8px !important;
  color: #001535 !important;
}

.currency-text-symbol-small {
  font-size: 6px !important;
  color: #001535 !important;
}

.currency-text-info-small {
  font-size: 7px !important;
  color: #263346 !important;
}

.header-bg {
  background-image: url("../assets/images/header.png");
  background-size: cover;
}

.footer-bg {
  background-image: url("../assets/images/footer.png");
}

.btn-primary {
  border: none !important;
  border-radius: 5px !important;
  background: #ceba85 !important;
  font-weight: bold !important;
  font-size: 12px !important;
  color: #2c2c2c !important;
  padding: 0.5rem 1.5rem !important;
}

.btn-primary:hover {
  border: none !important;
  border-radius: 5px !important;
  background: #bfa972 !important;
  font-weight: bold !important;
  font-size: 12px !important;
  color: #2c2c2c !important;
  padding: 0.5rem 1.5rem !important;
}

.btn-secondary {
  border: none !important;
  border-radius: 5px !important;
  background: #393939 !important;
  font-weight: bold !important;
  font-size: 10px !important;
  color: #ceba85 !important;
  padding: 0.3rem 1.3rem !important;
}

.btn-danger {
  border: none !important;
  border-radius: 5px !important;
  background: #dc3545 !important;
  /* font-weight: bold !important; */
  /* font-size: 10px !important; */
  color: #e7e7e7 !important;
  padding: 0.3rem 1.3rem !important;
}

.dropdown-toggle {
  color: #001535 !important;
  padding: 0rem 0rem !important;
}

.dropdown-item {
  color: #263346 !important;
}

.text-small {
  font-size: 8px !important;
}

.text-small1 {
  font-size: 9px !important;
}

.text-mid_footer {
  font-size: 12px !important;
}

.text-mid {
  font-size: 12px !important;
}

.text-mid:hover {
  background: #bfa972 !important;
}

.text-mid2 {
  font-size: 13px !important;
}

.text-large {
  font-size: 16px !important;
}

.text-large2 {
  font-size: 20px !important;
}

.text0 {
  color: #001535;
}

.text1 {
  color: #ceba85;
}

.text2 {
  color: #4e4e4e;
}

.text3 {
  color: #2c2c2c;
}

.qrcode-container {
  position: relative;
  width: fit-content;
  margin: 0 auto;
}

.qrcode-overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: contain;
  pointer-events: none;
  z-index: 1;
}

#sidebar.collapse {
  visibility: hidden;
}

#sidebar.collapse.show {
  visibility: visible;
  display: block;
}

#sidebar.collapsing {
  position: relative;
  height: 0;
  overflow: hidden;
  -webkit-transition-property: height, visibility;
  transition-property: height, visibility;
  -webkit-transition-duration: 0.35s;
  transition-duration: 0.35s;
  -webkit-transition-timing-function: ease;
  transition-timing-function: ease;
}

#sidebar.collapsing.width {
  -webkit-transition-property: width, visibility;
  transition-property: width, visibility;
  width: 0;
  height: auto;
}

.little-menu {
  flex-basis: 0;
  margin-right: 10px;
  display: grid;
  padding: 4px 0px;
}

.little-menu .btn-primary {
  border: none !important;
  border-radius: 0px !important;
  background: #fafafa !important;
  font-weight: normal !important;
  font-size: 8px !important;
  color: #001535 !important;
  padding: 0px 2px !important;
  margin: 1px !important;
}

.price-line {
  font-size: 9px !important;
  width: 100px;
  margin-top: 5px;
}

.price-number {
  margin-right: 3px;
  padding: 0px 3px;
  border-style: solid;
  border-radius: 3px;
  border-width: 1px;
  border-color: #001535;
}

.price-text {
  margin-right: 3px;
  padding-top: 2px;
}

.btn-menu {
  cursor: pointer;
  background-color: #fafafa;
  border-radius: 3px;
  margin: 4px;
  padding-left: 4px;
}

.dashboard-card {
  width: 160px;
  height: 240px;
  margin: 30px 15px;
  align-items: center;
  text-align: center;
  background-color: transparent !important;
  border-radius: 8px !important;
  padding-top: 8px !important;
  padding-bottom: 15px !important;
}

.form-control.small {
  display: block;
  width: 100%;
  height: 24px;
  padding: 0px 5px;
  font-size: 12px;
  font-weight: normal;
  line-height: 1.5;
  color: #737980;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid #737980;
  border-radius: 5px;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}

.hist-table table {
  width: 100% !important;
}

.hist-table th {
  border-top: 0px solid #fff !important;
  background-color: #e2e2e2;
  font-weight: medium !important;
  max-height: 10px !important;
  padding: 3px 0.75rem !important;
  vertical-align: middle !important;
}

.hist-table td {
  max-height: 10px !important;
  padding: 3px 0.75rem !important;
  border-top: 0px solid #fff !important;
  vertical-align: middle !important;
}

.table-striped > tbody > tr:nth-child(2n + 1) > td,
.table-striped > tbody > tr:nth-child(2n + 1) > th {
  background-color: #f1f1f1;
}

.modal-header {
  border-bottom: 0px solid #fff !important;
}

.modal-footer {
  border-top: 0px solid #fff !important;
  justify-content: center !important;
}

#overlay {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  transition: "background-color 0.5s";
  z-index: 10;
}

.kyc-title {
  text-align: center;
  background-color: #393939;
  color: #ceba85;
  font-size: 10px;
  font-weight: bold;
  padding: 10px 0px;
  margin: 0px auto;
  border-radius: 5px;
  width: 75px !important;
}

.kyc-title2 {
  text-align: center;
  background-color: #ceba85;
  color: #393939;
  font-size: 10px;
  font-weight: bold;
  padding: 10px 0px;
  margin: 0px auto;
  border-radius: 5px;
  width: 75px !important;
}

.kyc-icon-ok {
  text-align: center;
  font-size: 30px;
  color: #67d12b;
  margin-bottom: 30px;
}

.kyc-icon-waiting {
  text-align: center;
  font-size: 30px;
  color: #ffb006;
  margin-bottom: 30px;
}

.kyc-icon-waiting2 {
  text-align: center;
  font-size: 30px;
  color: #49a0ef;
  margin-bottom: 30px;
}

.kyc-icon-miss {
  text-align: center;
  font-size: 30px;
  color: #ee4539;
  margin-bottom: 30px;
}

.kyc-card {
  text-align: center;
  width: "100%";
  height: 300px;
  min-width: 210px;
  max-width: 270px;
  margin-top: 20px;
  margin-bottom: 5px;
  margin-left: auto;
  margin-right: auto;
  background-color: #fafafa !important;
  border-radius: 5x !important;
  padding: 20px !important;
  box-shadow: 0px 0px 5px 0px rgba(0, 0, 0, 0.3);
  -webkit-box-shadow: 0px 0px 5px 0px rgba(0, 0, 0, 0.3);
  -moz-box-shadow: 0px 0px 5px 0px rgba(0, 0, 0, 0.3);
}

.kyc-card2 {
  text-align: center;
  width: "100%";
  margin-top: 20px;
  margin-bottom: 5px;
  margin-left: auto;
  margin-right: auto;
  background-color: #fafafa !important;
  border-radius: 5x !important;
  padding: 20px !important;
  box-shadow: 0px 0px 5px 0px rgba(0, 0, 0, 0.3);
  -webkit-box-shadow: 0px 0px 5px 0px rgba(0, 0, 0, 0.3);
  -moz-box-shadow: 0px 0px 5px 0px rgba(0, 0, 0, 0.3);
}

.btn-voltar {
  margin-top: 20px;
  text-align: center;
  font-size: 12px !important;
  font-weight: bold;
  cursor: pointer;
}

.btn-voltar:hover {
  text-decoration: underline;
}

.form-dropdown .btn {
  text-align: left !important;
  display: block !important;
  width: 100% !important;
  height: calc(1.5em + 0.75rem + 2px) !important;
  padding: 0.375rem 0.75rem !important;
  font-size: 1rem !important;
  font-weight: 400 !important;
  line-height: 1.5 !important;
  color: #495057 !important;
  background-color: #fff !important;
  background-clip: padding-box !important;
  border: 1px solid #ced4da !important;
  border-radius: 10px !important;
  border-width: 1px !important;
  border-color: #ceba85 !important;
}

.recaptcha_style {
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
}

.doc_list {
  display: flex;
  flex-direction: column;
}

.orderbook {
  display: flex !important;
}

@media only screen and (min-width: 1200px) {
  .container {
    max-width: 1300px !important;
  }
}

@media only screen and (max-width: 620px) {

  .orderbook {
    display: block !important;
  }
  .recaptcha_style {
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
  }

  .sign-card {
    background-color: #fafafa9d !important;
    border-radius: 20px !important;
    padding: 20px !important;
    width: 80vw !important;
    box-shadow: 0px 0px 8px 0px rgba(0, 0, 0, 0.3);
    -webkit-box-shadow: 0px 0px 8px 0px rgba(0, 0, 0, 0.3);
    -moz-box-shadow: 0px 0px 8px 0px rgba(0, 0, 0, 0.3);
  }

}

.container-notifications {
  background-image: url("../assets/images/email.svg");
  margin-top: -18px;
  width: 36px;
  height: 36px;
  cursor: pointer;
  background-color: transparent;
  background-size: cover;
  background-position: center;
  position: relative;
}

.count-notifications {
  position: absolute;
  top: 0;
  right: -5px;
  background-color: #dc3545;
  min-width: 20px;
  min-height: 20px;
  padding: 3px;
  font-size: 0.65rem;
  border-radius: 50%;
  color: white;
  display: flex;
  justify-content: center;
  align-items: center;
  font-weight: 600;
}

.pending-count-status {
  background: #ed9900;
  width: 17px;
  height: 17px;
  border-radius: 100%;
  color: white;
}

.ldBar-label {
  color: #4e4e4e;

  font-size: 20px !important;

  font-weight: bold;
}

.copy-icon {
  width: 1.5rem;
  cursor: pointer;
}

.mark-all-as-read-btn {
  background-color: #ceba85 !important;
  font-size: 11px !important;
  font-weight: bold !important;
  cursor: pointer;
  padding: 5px !important;
}

.mark-all-as-read-btn:hover {
  padding: 5px !important;
}

.wallets-bar {
  background-color: #747474 !important;
}

::-webkit-scrollbar {
  width: 8px;
  height: 8px;
}

/* Track */
::-webkit-scrollbar-track {
  background: rgba(0, 21, 53, 0.7);
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #ceba85;
  transition: all 0.2s;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: rgba(206, 186, 133, 0.8);
}



.bg-red{
  /* background-color: red; */
  border-bottom: solid 2px black;
}

.doc-links{
  /* background-color: red; */
  border-bottom: solid 1px black;
  width: 15%;
  cursor: pointer;
}